import axios from "axios";
 
const fetchVideoSASToken = async (accessToken) => {
  let getTokenURL = process.env.REACT_APP_VIDEO_GETSASTOKEN_API_URL;
  try {
    const response = await axios.get(getTokenURL, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
    }); 
    return response.data.amsSasToken;
  } catch (error) {
    console.error("Error fetching token:", error);
    return '';
  }
};
 
export default fetchVideoSASToken;